import { Component, OnInit } from '@angular/core';

import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';

@Component({
  selector: 'noasy-about',
  templateUrl: './blockDiagramEditor.component.html',
  styleUrls: ['./blockDiagramEditor.component.scss']
})
export class BlockDiagramEditorComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  constructor() {}

  ngOnInit() {}
}
